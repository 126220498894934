import { FLIC_INSTITUTION_KIND_LIST } from '@/utils/constant'
export default {
  formDataGroups: [
    {
      title: 'General Information',
      name: 0,
      formDataItems: [
        {
          span: 24,
          formtype: 'switch',
          prop: 'active',
          label: 'Active',
          placeholder: '',
          value: true,
          object: {}
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'kind',
          label: 'Institution Type',
          placeholder: 'Please select the institution type',
          options: FLIC_INSTITUTION_KIND_LIST,
          object: {
            required: true,
            rules: [
              { required: true, message: 'Please select the institution', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'name',
          label: 'Institution Name',
          placeholder: 'Please enter the institution name',
          object: {
            required: true,
            rules: [
              { required: true, message: 'Please enter the institution name', trigger: 'blur' }
            ]
          }
        }
      ]
    }
  ]
}