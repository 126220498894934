<template>
  <el-card>
    <CommonForm
      label-position="right"
      label-width="130px"
      :formDataGroups="formDataGroups"
      :confirm-txt="confirmTxt"
      createDataType="institution"
      :apifunc="apifunc"
      :modifyData="modifyData"
    />
  </el-card>
</template>

<script>
import CommonForm from '@/components/CommonForm/index.vue'
import {
  getInstitutionInfo,
  createInstitution,
  updateInstitutionInfo
} from '@/services'
import jsonData  from './jsonData'
export default {
  components: {
    CommonForm
  },
  data () {
    return {
      formDataGroups: jsonData.formDataGroups,
      id: '',
      modifyData: null,
      apifunc: createInstitution,
      confirmTxt: 'Create'
    }
  },
  created () {
    this.id = this.$route.query.id
    if (this.id) {
      this.confirmTxt = 'Update'
      this.apifunc = (data) => updateInstitutionInfo(this.id, data)
      this.initInfo()
    }
  },
  methods: {
    async initInfo () {
      try {
        const res = await getInstitutionInfo(this.id)
        if (res.data.institution) {
          this.modifyData = res.data.institution
        }
      } catch (error) {
        
      }
    }
  }
}
</script>